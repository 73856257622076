// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
  },
};

class Blog_2016_05_14_Neon_Choprock extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>I finally descended Neon and Choprock canyons.</p>
        <p>
          The weather forecast was ideal, temperatures great, and the canyons
          were probably full. My friends who were doing the canyon with me were
          all in shape. Everything was lining up perfectly. At least until I
          started throwing up at the trailhead. This was going to be a memorable
          trip.
        </p>
        <p>
          Our plan was to drive down and camp at the Egypt trailhead. Friday we
          would backpack down to the Escalante, descend Neon, and camp near
          Choprock. Saturday we would descend Choprock. Sunday we would pack up,
          head back to the trailhead and go home. Our crew consisted of four
          canyoneers. Two of them had done Neon and one Choprock. This would be
          my first time for both.
        </p>
        <p>
          Last time I had thrown up was in Quandary, shortly before the direct
          section, where I filled a small pothole with my vomit. The canyon had
          been completely dry up until that point. That was a rough day.
        </p>
        <p>
          This time was different, though: I had a full day to recover before
          the big canyon. If I was lucky, I could recover and not be a liability
          come Saturday. If I was a liability, I was out. Choprock is such a
          long and arduous day and the line between success and disaster is so
          thin that I couldn’t put it on my crew to take me through there when I
          wasn’t at 100% capacity.
        </p>
        <p>
          I awoke the following morning feeling pretty terrible. My biggest
          concern was that I didn’t want to be a burden on anybody. We were on
          vacation after all. After a light breakfast and some moseying around I
          felt well enough to at least backpack with everyone down to the river.
          If I continued feeling better maybe I could do Neon with them.
        </p>
        <Image caption="Hiking down into the Escalante River" image={data._1} />
        <p>
          We started hiking down and I started feeling better –{' '}
          <em>a lot better</em>, actually. Well enough that I agreed to let them
          take me through Neon.
        </p>
        <h2>Neon</h2>
        <CanyonRatingCard rating="3BII" title="Neon" />
        <p>
          On the approach hike, we saw another group of canyoneers dropping in.
          Not wanting to butt up against them if we caught up to them, we
          decided to explore up canyon a half mile. For those of you wondering
          what is up there, it’s more beautiful canyon. It doesn’t have the high
          walls so it’s more exposed to the sun, but it was beautiful
          nonetheless. It added a couple of <GlossaryLink>rappels</GlossaryLink>
          , pools, and <GlossaryLink>down climbs</GlossaryLink>.
        </p>
        <Image
          caption="Neon Canyon right before the potholes and Golden Cathedral"
          image={data._2}
        />
        <p>
          The canyon was completely full – full enough that the keeper potholes
          would not have been found without people pointing out where they were,
          several feet below the water.
        </p>
        <Image
          caption="Narrows right before the normal drop in point"
          image={data._3}
        />
        <p>
          We caught up with several groups shortly before the big{' '}
          <GlossaryLink>keeper pothole</GlossaryLink> and chose to continue on
          through the final rappel so that we wouldn’t be stuck waiting at the
          end.
        </p>
        <p>
          The Golden Cathedral rappel is one of the most beautiful rappels ever.
          It goes through a blown out pothole into a shallow pool. There are two
          other holes beyond the rappel one that you can look up into.
        </p>
        <p>
          Unfortunately, I had seen so many pictures of the Golden Cathedral
          that it didn’t impact me as much as it could have had I never seen any
          pictures of it.
        </p>
        <Image caption="Golden Cathedral" image={data._4} />
        <p>
          After finishing the rappels we ate lunch, watched the subsequent
          groups rappel, and took a short nap while our wetsuits dried in the
          sun. That grotto is a great place to have lunch and relax. After an
          hour and a half, the final group still hadn’t finished rappelling
          (they were filming themselves so it was taking forever). I’m glad we
          pushed through before them.
        </p>
        <p>
          We hiked the short distance up closer toward Choprock and set up camp,
          had dinner, pumped water, and played in the river.
        </p>
        <h2>Choprock</h2>
        <CanyonRatingCard rating="3BIVRS" title="Choprock" />
        <p>
          We set alarms for 5:20 am with plans to leave at 5:30 but ended up
          leaving closer to 6:00. It was a cold morning and leaving the jackets
          behind was a difficult choice.
        </p>
        <Image caption="Flowers on the approach hike" image={data._5} />
        <p>
          Choprock is quite different from its Neon neighbor. It is very long
          and very skinny. Very long canyons usually mean more food, extra
          clothes for warmth, and more gear. Because it’s so skinny, you can’t
          bring anything you don’t absolutely need. I brought a Leprechaun
          backpack and put in 2 liters of water, some food, a small amount of
          <GlossaryLink>webbing</GlossaryLink>, and{' '}
          <GlossaryLink>VT Prusiks</GlossaryLink>. I rolled my 4/3 wetsuit up,
          wrapped it with some <GlossaryLink>accessory cord</GlossaryLink>, and
          clipped it to my pack with my spare{' '}
          <GlossaryLink id="carabiner">biners</GlossaryLink>. My{' '}
          <GlossaryLink>helmet</GlossaryLink> was clipped to the back, and I
          carried the rope on the approach. We also included a water filter in
          the group gear just in case. Though it was cold in the morning,
          leaving the jackets was a great idea. Within half an hour our bodies
          were warmed up from the walking and within an hour the sun was up.
        </p>
        <p>
          The approach is quite long at 6 miles. However, the uphill was gentle
          aside from a few short parts. This was the second time we had plans to
          do Choprock and I had really studied the approach well so I never had
          to pull out the GPS. The only things I would add to others’ beta would
          be the following:
        </p>
        <ol>
          <li>
            Find how to get up the lowest cliff the night before if possible.
            People have gotten lost there before. It added hours to their time
            and they ended up exiting in the dark. For the first cliff, you
            should be able to do it without using your hands, or using them just
            a little. If it looks any more difficult than than, you are in the
            wrong spot.
          </li>
          <li>
            When you make to the the second plateau and are wrapping around the
            north side, go down, down, down. Go as far down as you can and keep
            going down as you work your way up canyon.
          </li>
        </ol>
        <p>
          We made it to the bottom of the canyon at 8:30, had snacks, and put on
          wetsuits. The member of our group who had done Choprock before said
          the whole area was dry the last time he went though. It worried us
          that we might not be able to swim under the grim section obstacles,
          making our descent much more difficult. We started again at 9:00.
        </p>
        <Image caption="Rappel 1" image={data._6} />
        <p>
          Getting to the Riparian section consisted of difficult down climbs –
          standard for Escalante. That first rappel is incredible enough that I
          won’t spoil it here with a crappy description.
        </p>
        <p>
          I wasn’t particularly impressed with the Riparian section. If you have
          been through Kolob, it’s a lot like hiking the first few miles after
          the big rappel in the creek. Slippery rocks, lots of mud mixed with
          vegetation make it slow going whether you follow the water course or
          try to hike around it.
        </p>
        <p>
          The Riparian section ended abruptly as the walls closed in and the
          vegetation ended. I proudly announced that we were transitioning to
          the Happy section at 10:15.
        </p>
        <p>
          What can I say about the happy section? It’s beautiful. If Choprock
          ended in the happy section, it might be more popular than Neon. It
          certainly is more scenic.
        </p>
        <Image caption="Happy section" image={data._7} />
        <Image caption="Happy section" image={data._8} />
        <Image caption="Happy section" image={data._9} />
        <Image caption="Rappel 2" image={data._10} />
        <Image
          caption="We spent an estimated 30 minutes removing rocks from 5.10 Canyoneers"
          image={data._11}
        />
        <p>
          As soon as the water stopped flowing, we knew we were entering the
          Grim section. We stopped to pump water and eat lunch in the last
          remaining light – just a sliver creeping across the floor and up the
          wall. We were stopped long enough that I was starting to get chilled.
          Not good. We started again at 11:30.
        </p>
        <p>
          We had seen footprints at the beginning of the canyon. However, there
          were several parts in the Grim section where we had to push and climb
          over debris to get through. I can’t imagine someone had gone through
          there without having done that already.
        </p>
        <p>
          Just as I was starting to warm up again, we reached <em>the spot</em>.
          Thirty feet high easily. Very intimidating. I wasn’t cold anymore, but
          was feeling chilled knowing what this place was and its history. We
          were swimming at that point, pushing logs and debris forward trying to
          get through.
        </p>
        <p>
          The person in front had to stop and told us that it was no longer
          possible to push the debris forward. There was just too much and the
          walls were too skinny. He couldn’t climb over it, either. Our only
          solution was to chimney up and push the debris under and behind us. We
          were then able to go back down and continue on.
        </p>
        <p>
          We were lucky, though. There was a one or two foot gap between the
          water and the debris wall that we could swim under. Swimming under the
          debris is less strenuous, but no less dangerous. In subsequent debris
          walls where the water had been less disturbed by passage, there were
          small piles of sticks and junk right under the debris walls. The
          debris had the potential to collapse at any moment. It reminded me of
          that game where you put a bunch of sticks into a cylinder and then put
          a bunch or marbles on top. As we take turns removing sticks, more and
          more marbles fall through. Eventually there is a stick removed that
          sends all the marbles plunging down.
        </p>
        <Image caption="Biggest logjam from up canyon" image={data._12} />
        <Image caption="Biggest logjam from down canyon" image={data._13} />
        <p>
          Many of the debris walls had large logs sticking down into the water
          below. We took extra care not to touch these as we quickly swam
          underneath. Also, we only sent one person through at a time.
        </p>
        <p>
          There are other cool things about the Grim section that I won’t spoil
          here.
        </p>
        <Image caption="Short break between grims" image={data._14} />
        <Image caption="Final rappel" image={data._15} />
        <p>
          We finished the last rappel at 2:30, suited down, took a break, and
          started heading back to our campsite at 3:00. An hour later we arrived
          and decided we would have time to pack up and hike back to the Egypt
          trailhead that same day instead of camping another night. We took an
          hour to pack everything back up and left for Fence Canyon at 5:00.
        </p>
        <p>
          The hike out Fence was uneventful except for the last 500′ of
          elevation and 1/4 mile hiking. We had full packs with sleeping bags,
          stoves, etc. I think we were going on mile 14 and 2000 foot elevation
          gain, plus a mile of swimming, half a mile of down climbing, and 170
          feet of rappelling. Some of us started hitting our wall and we took
          that last steep section slowly.
        </p>
        <p>
          We arrived at the cars at 7:45, drove to Escalante and had dinner, and
          drove home, getting back at 2:30 am. That Sunday I got hit with my
          kidney disease and was wiped out for the next week.
        </p>
        <p>
          All in all I considered a successful trip. Everything went right, we
          were safe and efficient. I can’t wait to visit again.
        </p>
        <Image
          caption="Escalante River Drainage at the Egypt trailhead"
          image={data._16}
        />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2016-05-14-neon-choprock')(
  Blog_2016_05_14_Neon_Choprock
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/10.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/14.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/15.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: { eq: "images/blog/2016-05-14-neon-choprock/16.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
